import { useOktaAuth } from '@okta/okta-react'
import React, { useState } from 'react'
import { useAuth } from './authentication.hooks'
import { LoginLoader } from './LoginLoader'

export const CheckToken: React.FC = ({ children }) => {
    const auth = useAuth()
    const { oktaAuth } = useOktaAuth()
    const [isLoading, setIsLoading] = useState(false)

    if (auth.chefAuth.requestState === 'error' || auth.chefAuth.tokenError) {
        console.error('Access Denied')
        oktaAuth.signOut()
        return <div>Access Denied</div>
    }

    if (isLoading) {
        return <LoginLoader />
    }

    if (!auth.chefAuth.isAuthenticated && !isLoading) {
        setIsLoading(true)

        auth.setToken().then(() => {
            setIsLoading(false)
        })

        return <LoginLoader />
    }

    return <>{children}</>
}
