const subdomains = {
    'app-': 'app-local',
    'app-local': 'app-local',
    'app-prod': 'app',
    'app-staging': 'app-stage',
    'app-alpha': 'app-stage',
}

/**
 * Get the location from the window.
 */
export const getHostname = () => {
    return window && window.location && window.location.hostname
}

/**
 * Get the location origin from the window.
 */
export const getOrigin = () => {
    return window && window.location && window.location.origin
}

/**
 * Get the location pathname from the window.
 */
export const getPathName = () => {
    return window && window.location && window.location.pathname
}

export const determineifIsAlpha = (pathname: string) =>
    pathname.toLowerCase().startsWith('/alpha/')

export const determineIfIsDevelopment = (hostname: string) =>
    hostname.toLowerCase().includes('app-local.pluralsight.com')

export const determineIfIsStaging = (hostname: string) =>
    hostname.toLowerCase().includes('app-stage.pluralsight.com')

export const determineIfIsProd = (hostname: string) =>
    hostname.toLowerCase().includes('app.pluralsight.com')

/**
 * AppEnv is the indicator of where the app is running
 */
export const deriveAppEnv = ():
    | 'app-local'
    | 'app-alpha'
    | 'app-staging'
    | 'app-prod'
    | 'app-' => {
    if (determineIfIsDevelopment(getHostname())) {
        return 'app-local'
    } else if (determineIfIsStaging(getHostname())) {
        if (determineifIsAlpha(getPathName())) {
            return 'app-alpha'
        }
        return 'app-staging'
    } else if (determineIfIsProd(getHostname())) {
        return 'app-prod'
    }
    // log that something went wrong!
    return 'app-'
}

/**
 * EnvLabel is the environment label to display in the app
 */
export const deriveEnvLabel = (): 'local' | 'alpha' | 'staging' | '' =>
    deriveAppEnv().replace('app-', '').replace('prod', '') as
        | 'local'
        | 'alpha'
        | 'staging'
        | ''

const isDevelopment = deriveAppEnv() === 'app-local'
const isProduction = deriveAppEnv() === 'app-prod'
const isStaging = deriveAppEnv() === 'app-staging'
const isAlpha = deriveAppEnv() === 'app-alpha'
const routerBasePath: '/product-catalog/' | '/alpha/product-catalog' | '/' =
    isAlpha ? '/alpha/product-catalog' : '/product-catalog/'

// This helps us determine where we should be calling out to
export const getDomain = () => {
    const baseUri = 'https://__SUBDOMAIN__.pluralsight.com'
    return baseUri.replace('__SUBDOMAIN__', subdomains[deriveAppEnv()])
}

export const getSocketDomain = () => {
    const wsBasePath = isAlpha ? '/alpha/catalog/ws' : '/catalog/ws'
    return `wss://${subdomains[deriveAppEnv()]}.pluralsight.com${wsBasePath}`
}

/**
 * Returns the New Relic loader config for Browser instrumentation
 */
const getNewRelicConfig = () => {
    const agentID = isProduction
        ? '702834066' // prod
        : isStaging
        ? '696594958' // staging
        : '696603135' // dev

    return {
        agentID,
        applicationID: agentID,
    }
}

const SfBaseLink = (sfEnv: '' | '--e2e') =>
    `https://pluralsight${sfEnv}.lightning.force.com`

export const config = {
    appEnv: deriveAppEnv(),
    contentLibrariesUri: isProduction
        ? 'https://app.pluralsight.com/content-libraries'
        : 'https://app-stage.pluralsight.com/content-libraries',
    domain: getDomain(),
    envLabel: deriveEnvLabel(),
    hostname: getHostname(),
    isDevelopment,
    isProduction,
    isStaging,
    isAlpha,
    newRelic: getNewRelicConfig(),
    origin: getOrigin(),
    routerBasePath,
    slack: {
        channels: {
            supportBusinessUnit: 'fintech-support',
            supportTeam: 'fintech-support',
        },
    },
    sfUri: SfBaseLink(isProduction ? '' : '--e2e'),
}

export default config
