export namespace ProductEnum {
    export enum Category {
        Addon = 'Add On Services',
        Base = 'Base Products',
        Misc = 'Miscellaneous Products',
    }

    export enum CustomerType {
        All = 'All',
        Business = 'Business',
        Individual = 'Individual',
    }

    export enum Group {
        TECH = 'Tech Foundations',
        SKILLS = 'Skills Core',
        FLOW = 'Flow Core',
        CLOUD = 'Cloud Core',
    }

    export enum LibraryScope {
        Full = 'Full',
        Limited = 'Limited',
        None = 'None',
    }

    export enum Type {
        Event = 'Event',
        Flow = 'Flow',
        Gift = 'Gift',
        Proserv = 'ProServ',
        Skills = 'Skills',
        Acg = 'ACG',
    }
}
