import { Table, Tag, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { Common } from '../../../store/typings/common.interface'
import { CurrencyEnum } from '../../../store/typings/currency.enum'
import { ProductOption } from '../../../store/typings/product-option.interface'
import { PAGE_SIZE } from '../../products/components/ProductsTable'
import { ImportOutcome, outcomeColors } from './ImportOutcome'
import styles from './ReviewImport.module.scss'

type ImportResponse = ProductOption.ImportResponse
const { USD, EUR, GBP, INR, ZAR } = CurrencyEnum.Code

const DiffField: React.FC<{
    outcome: Common.Outcome
}> = ({ outcome, children, ...rest }) => (
    <span style={{ color: outcomeColors[outcome], fontWeight: 500 }} {...rest}>
        {children}
    </span>
)
interface Props {
    data: ImportResponse[]
}

export const ReviewTable: React.FC<Props> = ({ data }) => {
    const forTable = data.filter((d) => d.outcome !== 'none')

    const withDiff = (field: string) => (value: any, record: ImportResponse) =>
        renderDiffField(field, value, record.productOption)

    const boolWithDiff =
        (field: string) => (value: any, record: ImportResponse) =>
            renderDiffField(
                field,
                value === false ? 'No' : 'Yes',
                record.productOption
            )

    const priceWithDiff =
        (currency: CurrencyEnum.Code) => (value: any, record: ImportResponse) =>
            renderDiffField('price', value, record.priceOptions[currency])

    const taxCodeWithDiff = () => (value: string, record: ImportResponse) =>
        renderDiffField(
            'taxCode',
            record?.priceOptions?.USD?.entity?.taxCode || '',
            record.priceOptions.USD
        )

    const renderDiffField = (field: string, value: string, record: any) => {
        if (record?.outcome === 'update') {
            const diff = record?.diffs.find((d: any) => d.path[0] == field)
            if (diff) {
                return (
                    <Tooltip title={diff.lhs}>
                        <DiffField outcome={'update'}>{value}</DiffField>
                    </Tooltip>
                )
            }
        }

        if (record?.outcome === 'create') {
            return <DiffField outcome={'create'}>{value}</DiffField>
        }

        return value
    }

    const columns: ColumnProps<ImportResponse>[] = [
        {
            title: 'OUTCOME',
            dataIndex: 'outcome',
            fixed: 'left',
            render: (value: Common.Outcome) => (
                <ImportOutcome outcome={value} />
            ),
            sorter: (a, b) => sortAlpha(a.outcome, b.outcome),
            width: 80,
        },
        {
            title: 'NAME',
            dataIndex: idx('name'),
            render: (value, record) => (
                <div>
                    <span>{withDiff('name')(value, record)}</span>
                    {record.freeTrial && (
                        <Tooltip title={'Free trial will also be created'}>
                            <Tag
                                color={outcomeColors.create}
                                style={{ marginLeft: 8 }}
                            >
                                FT
                            </Tag>
                        </Tooltip>
                    )}
                </div>
            ),
            fixed: 'left',
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.name,
                    b.productOption.entity.name
                ),
            width: 300,
        },
        {
            title: 'INVOICE NAME',
            dataIndex: idx('invoiceName'),
            render: withDiff('invoiceName'),
            width: 300,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.invoiceName,
                    b.productOption.entity.invoiceName
                ),
        },
        {
            title: 'STATUS',
            dataIndex: idx('status'),
            render: withDiff('status'),
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.status,
                    b.productOption.entity.status
                ),
            width: 120,
        },
        {
            title: `PRICE (${USD})`,
            dataIndex: priceIdx(USD),
            render: priceWithDiff(USD),
            width: 120,
            sorter: sortPrice(USD),
        },
        {
            title: `PRICE (${EUR})`,
            dataIndex: priceIdx(EUR),
            render: priceWithDiff(EUR),
            width: 120,
            sorter: sortPrice(EUR),
        },
        {
            title: `PRICE (${GBP})`,
            dataIndex: priceIdx(GBP),
            render: priceWithDiff(GBP),
            width: 120,
            sorter: sortPrice(GBP),
        },
        {
            title: `PRICE (${INR})`,
            dataIndex: priceIdx(INR),
            render: priceWithDiff(INR),
            width: 120,
            sorter: sortPrice(INR),
        },
        {
            title: `PRICE (${ZAR})`,
            dataIndex: priceIdx(ZAR),
            render: priceWithDiff(ZAR),
            width: 120,
            sorter: sortPrice(ZAR),
        },
        {
            title: 'CUSTOMER',
            dataIndex: idx('customerSource'),
            render: withDiff('customerSource'),
            width: 120,
            ellipsis: true,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.customerSource,
                    b.productOption.entity.customerSource
                ),
        },
        {
            title: 'CHARGE TYPE',
            dataIndex: idx('chargeType'),
            render: withDiff('chargeType'),
            width: 120,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.chargeType,
                    b.productOption.entity.chargeType
                ),
        },
        {
            title: 'BILLING PERIOD',
            dataIndex: idx('billingPeriod'),
            render: withDiff('billingPeriod'),
            width: 120,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.billingPeriod,
                    b.productOption.entity.billingPeriod
                ),
        },
        {
            title: 'CHARGE MODEL',
            dataIndex: idx('chargeModel'),
            render: withDiff('chargeModel'),
            width: 120,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.chargeModel,
                    b.productOption.entity.chargeModel
                ),
        },
        {
            title: 'UOM',
            dataIndex: idx('uom'),
            render: withDiff('uom'),
            width: 120,
            ellipsis: true,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.uom,
                    b.productOption.entity.uom
                ),
        },
        {
            title: 'COUNTRY',
            dataIndex: idx('countryCode'),
            render: withDiff('countryCode'),
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.countryCode,
                    b.productOption.entity.countryCode
                ),
            width: 100,
        },
        {
            title: 'DEFAULT ID',
            dataIndex: idx('defaultRatePlanCountryId'),
            render: withDiff('defaultRatePlanCountryId'),
            width: 180,
            ellipsis: true,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.defaultRatePlanCountryId,
                    b.productOption.entity.defaultRatePlanCountryId
                ),
        },
        {
            title: 'CHECKOUT',
            dataIndex: idx('digitalCheckout'),
            render: boolWithDiff('digitalCheckout'),
            width: 100,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.digitalCheckout,
                    b.productOption.entity.digitalCheckout
                ),
        },
        {
            title: 'EXPAND',
            dataIndex: idx('digitalExpand'),
            render: boolWithDiff('digitalExpand'),
            width: 100,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.digitalExpand,
                    b.productOption.entity.digitalExpand
                ),
        },
        {
            title: 'RENEW',
            dataIndex: idx('digitalRenew'),
            render: boolWithDiff('digitalRenew'),
            width: 100,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.digitalRenew,
                    b.productOption.entity.digitalRenew
                ),
        },
        {
            title: 'TAXABLE',
            dataIndex: idx('taxable'),
            render: boolWithDiff('taxable'),
            width: 100,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.taxable,
                    b.productOption.entity.taxable
                ),
        },
        {
            title: 'TAX CODE',
            dataIndex: 'taxCode',
            render: taxCodeWithDiff(),
            width: 120,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.priceOptions[0].taxCode,
                    b.productOption.entity.priceOptions[0].taxCode
                ),
        },
        {
            title: 'FREE',
            dataIndex: idx('free'),
            render: boolWithDiff('free'),
            width: 100,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.free,
                    b.productOption.entity.free
                ),
        },
        {
            title: 'FREE TRIAL',
            dataIndex: idx('freeTrial'),
            render: boolWithDiff('freeTrial'),
            width: 100,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.freeTrial,
                    b.productOption.entity.freeTrial
                ),
        },
        {
            title: 'PS ONE',
            dataIndex: idx('isPluralsightOne'),
            render: boolWithDiff('isPluralsightOne'),
            width: 100,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.isPluralsightOne,
                    b.productOption.entity.isPluralsightOne
                ),
        },
        {
            title: 'OFFER CODE',
            dataIndex: idx('useForOfferCodeRedemption'),
            render: boolWithDiff('useForOfferCodeRedemption'),
            width: 100,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.useForOfferCodeRedemption,
                    b.productOption.entity.useForOfferCodeRedemption
                ),
        },
        {
            title: 'MARKETING ID',
            dataIndex: idx('marketingId'),
            render: withDiff('marketingId'),
            width: 180,
            ellipsis: true,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.marketingId,
                    b.productOption.entity.marketingId
                ),
        },
        {
            title: 'ROYALTY',
            dataIndex: idx('royaltyBearing'),
            render: boolWithDiff('royaltyBearing'),
            width: 100,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.royaltyBearing,
                    b.productOption.entity.royaltyBearing
                ),
        },
        {
            title: 'SFDC PRODUCT ID',
            dataIndex: idx('sfdcProductId'),
            render: withDiff('sfdcProductId'),
            width: 180,
            ellipsis: true,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.sfdcProductId,
                    b.productOption.entity.sfdcProductId
                ),
        },
        {
            title: 'TEST ID',
            dataIndex: idx('testId'),
            render: withDiff('testId'),
            width: 180,
            ellipsis: true,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.testId,
                    b.productOption.entity.testId
                ),
        },
        {
            title: 'LEGACY SKU',
            dataIndex: idx('legacyProductSku'),
            render: withDiff('legacyProductSku'),
            width: 180,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.legacyProductSku,
                    b.productOption.entity.legacyProductSku
                ),
        },
        {
            title: 'PRODUCT ID',
            dataIndex: idx('productCatalogId'),
            render: withDiff('testId'),
            width: 180,
            ellipsis: true,
            sorter: (a, b) =>
                sortAlpha(
                    a.productOption.entity.productCatalogId,
                    b.productOption.entity.productCatalogId
                ),
        },

        {
            title: 'FREE TRIAL PRODUCT',
            dataIndex: ['freeTrial', 'product', 'name'],
            render: (id) => <DiffField outcome={'create'}>{id}</DiffField>,
            width: 310,
            ellipsis: true,
            sorter: (a, b) =>
                sortAlpha(a.freeTrial?.product.name, b.freeTrial?.product.name),
        },
    ]

    return (
        <Table
            size="small"
            className={styles.table}
            columns={columns}
            dataSource={forTable}
            rowKey={(record) => record.productOption.entity.id}
            scroll={{ x: 5000 }}
            pagination={{ defaultPageSize: PAGE_SIZE }}
        />
    )
}

const idx = (field: string) => ['productOption', 'entity', field]
const priceIdx = (currency: string) => [
    'priceOptions',
    currency,
    'entity',
    'price',
]

const sortAlpha = (a: any, b: any) =>
    a?.toString().localeCompare(b?.toString()) ?? 0

const sortPrice =
    (currency: CurrencyEnum.Code) => (a: ImportResponse, b: ImportResponse) => {
        const priceA = a.priceOptions[currency]?.entity.price
        const priceB = b.priceOptions[currency]?.entity.price

        if (priceA) {
            if (priceB) {
                return priceA - priceB
            }
            return -1
        }
        return 1
    }
