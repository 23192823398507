import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js'
import axios, { AxiosResponse, Method } from 'axios'
import config, { getOrigin } from '../config'

const baseURL = `${getOrigin()}${
    config.isAlpha ? '/alpha/product-catalog' : '/product-catalog'
}`

const CLIENT_PROD = '0oacb1znv1plUmWCv1t7'
const ISSUER_PROD = 'https://pluralsight.okta.com'

const CLIENT_PREVIEW = '0oapys3tr4W4YSunp0h7'
const ISSUER_PREVIEW = 'https://pluralsight.oktapreview.com'

const CLIENT_ALPHA = '0oa1p3bscc01ONBYd0h8'
const ISSUER_ALPHA = 'https://pluralsight.oktapreview.com'

const defaults: OktaAuthOptions = {
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: true,
    devMode: false,
    issuer: config.isProduction
        ? ISSUER_PROD
        : config.isAlpha
        ? ISSUER_ALPHA
        : ISSUER_PREVIEW,
    tokenManager: {
        storageKey: `okta-token-storage-pc${config.isAlpha ? '-alpha' : ''}`, // default: okta-token-storage
    },
}

export const oktaConfig: OktaAuthOptions = {
    clientId: config.isProduction
        ? CLIENT_PROD
        : config.isAlpha
        ? CLIENT_ALPHA
        : CLIENT_PREVIEW,
    redirectUri: `${baseURL}/login/callback`,
    postLogoutRedirectUri: baseURL,
    ...defaults,

    /**
     * Use this method to override the http client used for okta.
     * This allows you to override the header settings.
     */
    httpRequestClient: async (method, url, args) => {
        const axiosMethod = method as Method

        const response: AxiosResponse<any> = await axios.request({
            method: axiosMethod,
            url,
            data: args.data,
            withCredentials: args.withCredentials,
            headers: {
                ...args.headers,
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0',
            } as Record<string, string>,
        })

        return response.request
    },
}

export const oktaAuth = new OktaAuth(oktaConfig)
